/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 *
 * Google CDN, Latest jQuery
 * To use the default WordPress version of jQuery, go to lib/config.php and
 * remove or comment out: add_theme_support('jquery-cdn');
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages

				$("#header-logo-svg-full rect").hover(
					function() {
						$(this).siblings(".header-logo-full.inactive").attr("fill", "#132F47");
					},
					function() {
						$(this).siblings(".header-logo-full.inactive").attr("fill", "#286294");
					}
				);

				$("#menu-primary-navigation").find(".dropdown-toggle").attr("data-toggle", "");
				$("#menu-user-navigation").children("li:not(.menu-search)").find(".dropdown-toggle").attr("data-toggle", "");
				$("#mobile-menu-primary-navigation").find(".dropdown-toggle").attr("data-toggle", "");
				$("#menu-event-site-navigation").find(".dropdown-toggle").attr("data-toggle", "");

				$("#main-site-nav-toggle .navbar-toggle").on("click", {status: "open"}, toggleNav);
				$("#main-site-slide-in-nav .close-btn").on("click", {status: "close"}, toggleNav);
				$(window).on("click", {status: "close"}, function(event) {
					if (event.target === document.body) {toggleNav(event);}
				});

				$("#search-dropdown-close").on("click", function(){
					$(".menu-search.open .dropdown-toggle").dropdown("toggle");

				});

				if($("#testimonial-carousel .item.active").has(".top-level-content-holder") &&
					$("#testimonial-carousel .item.active .top-level-content-holder").html() !== "")
				{

					$("#testimonial-carousel .top-level-wrap").html($("#testimonial-carousel .item.active .top-level-content-holder").html());

				}

				$("#testimonial-carousel")
					.on("slid.bs.carousel", function(evt){

						$(".top-level-wrap", this).html($(evt.relatedTarget).find(".top-level-content-holder").html());

						$(".top-level-wrap > div:first-child", this).css("opacity", 0).fadeTo("fast", 1);

					})
					.on("slide.bs.carousel", function(evt){

						$(this).find(".top-level-wrap > div:first-child").fadeTo("fast", 0);

					});

				// Content list infinite feed
				if($("button.view-more-btn").length > 0){

					if($("#list-content-loader").length < 1){
						$(".list-more-items-btn").hide();
					}

					$("button.view-more-btn").on("click", function(){

						var spinnerIcon = $(this).find("span.fa-spinner");
						var contentLoader = $("#list-content-loader");

						var viewMoreBtn = $(this);

						spinnerIcon.css("display", "inline-block");

						var action = "get_posts";

						if((contentLoader.data("action") !== undefined) && (contentLoader.data("action") !== "")) {
							action = contentLoader.data("action");
						}

						$.post(
							ajaxVars.ajaxurl,
							{
								"action": action,
								"data": $.param(contentLoader.data())
							},
							function(data){

								contentLoader.remove();

								if(data){

									spinnerIcon.hide();

									$("#content-list .event-block-wrap").append(data);

									// Look for the new content loader tag
									// If it's not there, there aren't any more posts to show so hide the view more button
									if($("#list-content-loader").length < 1){
										viewMoreBtn.parent().remove();
									}

								}else{

									viewMoreBtn.parent().remove();

									$("#content-list").append('<p>No more posts available</p>');

								}

							}
						);

					});

				}

				$("#footer-testimonial").load(ajaxVars.ajaxurl + "?action=get_random_testimonial");

				// Enhanced animations for collapsible elements

				$(".panel-collapse")
					.on("show.bs.collapse", function(){
						$(this).parent().addClass("panel-open");
					})
					.on("hide.bs.collapse", function(){
						$(this).parent().removeClass("panel-open");
					});

				$(".panel-collapse.in").parent().addClass("panel-open");

				$('input[type="radio"][data-toggle="tab"]').on("shown.bs.tab", function(evt){
					$(evt.currentTarget).prop("checked", true);
				});

				// Add to cart button functionality

				$(".js-add-to-cart-btn").on("click", function(){

					var addToCartBtn = $(this);

					var origBtnText = addToCartBtn.text();

					addToCartBtn
						.prop("disabled", true)
						.html($("<span>").addClass("fa fa-pulse fa-spinner"));

					var data = {
						"post_id": 		$(this).data("post-id"),
						"post_type": 	$(this).data("post-type")
					};

					if(typeof $(this).data("extra-params") !== undefined && $(this).data("extra-params") !== ""){
						data.extra_params = $(this).data("extra-params");
					}

					$.post(
						ajaxVars.store_ajaxurl,
						{
							"action": "add_to_cart",
							"data": $.param(data)
						},
						function(data) {

							if(data.status){

								addToCartBtn.text(addToCartBtn.data("completed-text"));

								// addToCartBtn.removeClass("btn-primary").addClass("btn-default-inverse");

								if(addToCartBtn.data("success-msg") !== undefined){
									triggerAddToCartModal(addToCartBtn, addToCartBtn.data("success-msg"), "success");
								}

								addToCartBtn.trigger("rm.cart.updated");

							}else{

								var errorMsg = "There was an error adding the item to the cart.  Please try again.";

								if(typeof data.message === "string" && data.message !== ""){
									errorMsg = data.message;
								}

								triggerAddToCartModal(addToCartBtn, errorMsg, "error");

								addToCartBtn.prop("disabled", false).text(origBtnText);

							}

						}

					);

				});

				if($("#signup-fields, #billing-info, #account-company-details-fields").length > 0) {

					$("#signup-fields, #billing-info, #account-company-details-fields")
						.addressfield(addressFieldConfig)
						.on("addressfield:after", function (e, data) {

							$(".locality-wrap:hidden", this).find(".form-group :input").prop("disabled", true);

							// Ensure that any hidden fields in the top locality-wrap div are also set to disabled
							$(".locality-wrap", this).first().children(".form-group:hidden").find(":input:hidden").prop("disabled", true);

							// Ensure that any fields in the top locality-wrap div that might
							// have been previously hidden and disabled are now enabled
							$(".locality-wrap", this).first().children(".form-group:not(:hidden)").find(":input:disabled").prop("disabled", false);

							// Update required attribute for any field based on json file

							// Create a field config object that we can actually use
							var fieldsConfig = {};

							for(var i in data.config.fields){

								// Get first key and store that key's value
								for(var j in data.config.fields[i]){
									fieldsConfig[j] = data.config.fields[i][j];
									break;
								}

							}

							// Loop through all fields and see if any config data exists for the field
							for(var fieldKey in data.fieldMap){

								// If there is config data and the "required" setting exists
								if((typeof fieldsConfig[fieldKey] !== "undefined") && (typeof fieldsConfig[fieldKey].required !== "undefined")){

									// Set field's required property to value defined in addressfield json
									$(data.fieldMap[fieldKey], this).attr("required", fieldsConfig[fieldKey].required);

								}else{

									// Otherwise default field's required property to true
									$(data.fieldMap[fieldKey], this).attr("required", true);

								}

							}

							$(".locality-wrap label").each(function () {

								if ($(this).text().slice(-1) !== "*") {
									$(this).append("*");
								}

							});

						});

				}

				if($("#signup-fields").length > 0){

					$("#signup-fields").append(
						$("<input>")
							.prop({
								"type": "hidden",
								"name": "form_data[signup-fields][honey_pot]"
							})
					);

				}

				if($("#date-filter-datepicker-trigger").length > 0) {

					$("#date-filter-datepicker-trigger").daterangepicker(
						{
							startDate: $("#date-filter-datepicker-trigger").data("start-date"),
							endDate: $("#date-filter-datepicker-trigger").data("end-date"),
							showDropdowns: true,
							linkedCalendars: false,
							opens: "center",
							locale: {
								format: $("#date-filter-datepicker-trigger").data("date-format")
							},
							applyClass: "btn-primary",
							cancelClass: "btn-secondary"
						},
						function (start, end) {

							var trigger = $("#date-filter-datepicker-trigger");

							var dateFormat = trigger.data("date-format");

							$(trigger.data("start-date-target")).val(start.format(dateFormat));
							$(trigger.data("end-date-target")).val(end.format(dateFormat));

						}
					).on("click", function (evt) {
						return false;
					});

				}

				$("#event-listing-filter-submit").on("click", function(){

					var redirectUrl = ((ajaxVars.root_url !== undefined) ? ajaxVars.root_url : "");

					if($('#event-listing-filter form :input[name="sector"]').val() !== ""){

						redirectUrl += "/conference/" + $('#event-listing-filter form :input[name="sector"]').val() + "/";

					} else {

						redirectUrl += "/conferences/";

					}

					var queryString = $('#event-listing-filter form :input[name!="sector"]').filter(function(){
						return (this.value !== "");
					}).serialize();

					if(queryString !== ""){
						redirectUrl += "?" + queryString;
					}

					window.location.href = redirectUrl;

					return false;

				});

				$("#js-event-filter-toggle").on("click", function(){
					$("#event-filter-bar").toggle();
					$("#js-clear-filter-btn").parent().toggle();
					$(this).text(function(i, existingVal){
						return (existingVal === "Hide filter") ? "Show filter" : "Hide filter";
					});
					return false;
				});

				$("#js-clear-filter-btn").on("click", function(){

					$("#event-listing-filter form :input").val("");

					var redirectUrl = ((ajaxVars.root_url !== undefined) ? ajaxVars.root_url : "") + "/conferences/";

					if(redirectUrl !== window.location.href){
						window.location.href = redirectUrl;
					}

					return false;

				});

				// Update cart icon with proper number on page load, to get around page caching
				updateCartItemCount();

				$("body").on("rm.cart.updated", function(){
					updateCartItemCount();
				});

				$(".locality-wrap").on("blur", ".input-postal-code .form-control", function(){

					var postalCodePattern = /^[A-Z][0-9][A-Z]\ *[0-9][A-Z][0-9]$/;

					var postalCodeVal = $(this).val().toUpperCase().trim();

					if(postalCodePattern.test(postalCodeVal)){

						// If the value contains a space
						if(postalCodeVal.indexOf(" ") >= 0){

							// Replace any instance of one or more spaces with a space
							postalCodeVal = postalCodeVal.replace(/\ +/g, " ");

						}else{

							postalCodeVal = postalCodeVal.substr(0, 3) + " " + postalCodeVal.substr(3);

						}

						$(this).val(postalCodeVal);

					}

				});

				// Back to top button behaviour

				if ($("#back-to-top-container").length > 0) {

					$('html').css('scroll-behavior', 'smooth');

					var backToTop = $("#back-to-top-container");

					if ($(window).scrollTop() > 325) {

						backToTop.show();

					}

					$(window).scroll(function() {

						if ($(window).scrollTop() > 325) {

							if (backToTop.is(":hidden")) {

								backToTop.show();

							}

						} else {

							if (backToTop.is(":visible")) {

								backToTop.hide();

							}

						}

					});

					$("#back-to-top-btn").click(function() {

						$(window).scrollTop(0);

					});

				}
                                
                                //bootstrap dropdown on mobile
//                                $(".dropdown").click(function(e){
//                                    if( $(this).children(".dropdown-menu").hasClass("show-dropdown")){
//                                        $(this).children(".dropdown-menu").removeClass("show-dropdown" );
//                                    }
//                                    else{
//                                        $(this).children(".dropdown-menu").addClass("show-dropdown" );
//                                    }
//                                    
//                                   // e.preventDefault();
//                                });

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
				$('html').css('scroll-behavior', 'smooth');

				// Sort listings ASC
				var $eventBlockWrap = $('.event-block-wrap');
				$eventBlockWrap.find('.event-block').sort(function(a, b) {
					return a.getAttribute('data-event-time') - b.getAttribute('data-event-time');
				})
				.appendTo($eventBlockWrap);

				// Show event listings
				$('.event-block-wrap').css('opacity', '1');
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		'blog':{
			init: function(){
				var clickCount = 0;
				//blog categories toggle for blog listing page
				$('.category-toggle').click(function(){
					clickCount++;
					if ( clickCount % 2 === 1 ){
						$('#blog-topics-list').show();
						$('.category-toggle').html('Hide Topics');
					}
					else{
						$('#blog-topics-list').hide();
						$('.category-toggle').html('Show Topics');
					}
				});
			}
		},
		// Account page
		'um_page_account': {
			init: function () {
				// JavaScript to be fired on the account page

				if($(".um-account-link.current").data("tab") !== "general"){

					$(".um-account-main .um-account-tab-general")
						.find(".form-group :input").prop("disabled", true);

				}

				$(".um-account-link").on("click", function(){

					if($(this).data("tab") === "general"){

						$(".um-account-main .um-account-tab-" + $(this).data("tab"))
							.find(".form-group :input").prop("disabled", false);

					}else if($(".um-account-link.current").data("tab") === "general"){

						$(".um-account-main .um-account-tab-" + $(".um-account-link.current").data("tab"))
							.find(".form-group :input").prop("disabled", true);

					}

				});

			}
		},
		// Virtual series landing page
		'page_template_page_virtual_series_landing': {
			init: function () {
				// JavaScript to be fired on the virtual series landing page
				landingCleanSort("ASC");
				landingFilterGenerator();
				landingFilterFunc();
				backToTopBtn();
			}
		},
		// On-Demand landing page
		'page_template_page_ondemand_landing': {
			init: function () {
				// JavaScript to be fired on the on-demand landing page
				landingCleanSort("DSC");
				landingFilterGenerator();
				landingFilterFunc();
				backToTopBtn();
			}
		},
		// Live events landing page
		'page_template_page_live_events_landing': {
			init: function () {
				// JavaScript to be fired on the live events landing page
				landingCleanSort("ASC");
				landingFilterGenerator();
				landingFilterFunc();
				backToTopBtn();
			}
		},
		// Livestream events landing page
		'page_template_page_livestream_events_landing': {
			init: function () {
				// JavaScript to be fired on the livestream events landing page
				landingCleanSort("ASC");
				landingFilterGenerator();
				landingFilterFunc();
				backToTopBtn();
			}
		},
		// By sector landing page
		'page_template_page_bysector_landing': {
			init: function () {
				// JavaScript to be fired on the by sector landing page
				landingCleanSort("ASC");
				landingFilterGenerator();
				landingFilterFunc();
				backToTopBtn();
			}
		},
		// By location landing page
		'page_template_page_bylocation_landing': {
			init: function () {
				// JavaScript to be fired on the by location landing page
				landingCleanSort("ASC");
				landingFilterGenerator();
				landingFilterFunc();
				backToTopBtn();
			}
		},
		// Speaker permission page
		'page_template_page_speaker_permission': {
			init: function () {
				// JavaScript to be fired on the speaker permission page

				$(document).on('nfFormReady', function(){

					// Setup select2 for events list multiselect field
					var eventsList = [{id: 'All events', text: 'All events'}];
					$.each(upcomingEvents, function(i, v) {
						var eventTitleHTML = $.parseHTML(v);
						var eventTitle = $(eventTitleHTML).text();
						eventsList.push({
							id: eventTitle,
							text: eventTitle
						});
					});
					$('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:first-of-type .nf-field-element > select').select2({
						closeOnSelect: false,
						data: eventsList,
						multiple: true,
						placeholder: 'Please select all events that apply',
						scrollAfterSelect: true,
						dropdownParent: $('.listmultiselect-container .nf-field-element')
					});

					// Setup internal email field setter
					$('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:first-of-type .nf-field-element > select').on('select2:select select2:unselect select2:clear change', function() {
						var internalEmailArray = [];
						var selectedEvents = $('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:first-of-type .nf-field-element > select').select2('data');
						$.each(selectedEvents, function() {
							var selectedSlug = $(this)[0].id.replace(/&/g, '&#038;').replace(/’/g, '&#8217;').replace(/"/g, '&quot;').replace(/–/g, '&#8211;');
							$.each(internalEmailRaw, function() {
								var internalEmailObj = $(this)[0];
								if (_.has(internalEmailObj, selectedSlug)) {
									internalEmailArray.push(internalEmailObj[selectedSlug]);
								} else if (selectedSlug === 'All events') {
									internalEmailArray.push(internalEmailObj[Object.keys(internalEmailObj)[0]]);
								}
							});
						});
						internalEmailArray = _.uniq(internalEmailArray.join().replace(/\s+/g, '').split(','));
						$('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:nth-of-type(2) .nf-field-element > input').val(internalEmailArray.join());
					});

					// Set multiselect field default event and lock if applicable
					var preselectedEventHTML = $.parseHTML(preselectedEvent);
					var preselectedEventText = $(preselectedEventHTML).text();
					var eventsListLockedHTML = $.parseHTML(eventsListLocked);
					var eventsListLockedText = $(eventsListLockedHTML).text();
					if (preselectedEventText !== "") {
						$('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:first-of-type .nf-field-element > select').val(preselectedEventText).trigger('change');
						if (eventsListLockedText === "true") {
							$('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:first-of-type .nf-field-element > select').prop('disabled', true);
						}
					}

					// Add required asterisk to recaptcha label
					$('.recaptcha-wrap label').html(function(i, data) { return data + '<span class="ninja-forms-req-symbol">*</span>'; });
				
				});

			}
		},
		// Livestream conferences page
		'page_template_page_livestream_conferences': {
			init: function () {
				// JavaScript to be fired on the livestream conferences page

				$(document).on('nfFormReady', function(){

					// Setup select2 for events list multiselect field
					var eventsList = [{id: 'All events', text: 'All events'}];
					$.each(upcomingEvents, function(i, v) {
						var eventTitleHTML = $.parseHTML(v);
						var eventTitle = $(eventTitleHTML).text();
						eventsList.push({
							id: eventTitle,
							text: eventTitle
						});
					});
					$('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:first-of-type .nf-field-element > select').select2({
						closeOnSelect: false,
						data: eventsList,
						multiple: true,
						placeholder: 'Please select all events that apply',
						scrollAfterSelect: true,
						dropdownParent: $('.listmultiselect-container .nf-field-element')
					});

					// Set multiselect field default event and lock if applicable
					var preselectedEventHTML = $.parseHTML(preselectedEvent);
					var preselectedEventText = $(preselectedEventHTML).text();
					var eventsListLockedHTML = $.parseHTML(eventsListLocked);
					var eventsListLockedText = $(eventsListLockedHTML).text();
					if (preselectedEventText !== "") {
						$('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:first-of-type .nf-field-element > select').val(preselectedEventText).trigger('change');
						if (eventsListLockedText === "true") {
							$('.nf-form-cont form .nf-form-content .nf-mp-body nf-rows-wrap > div.nf-row:first-of-type .nf-field-element > select').prop('disabled', true);
						}
					}

					// Add required asterisk to recaptcha label
					$('.recaptcha-wrap label').html(function(i, data) { return data + '<span class="ninja-forms-req-symbol">*</span>'; });
				
				});

				initializeSmartForm();

			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

	var triggerAddToCartModal = function(target, modalMsg, type){

		if(typeof type === undefined){
			type = "";
		}

		if($("#js-add-to-cart-modal").length > 0){

			$("#js-add-to-cart-modal").find(".modal-body").html(modalMsg);

			if(type === "error"){

				$("#js-add-to-cart-modal").find(".modal-footer .btn-view-cart").hide();
				$("#js-add-to-cart-modal").find(".modal-footer .btn-continue").hide();
				$("#js-add-to-cart-modal").find(".modal-footer .btn-close").show();

			}else{

				$("#js-add-to-cart-modal").find(".modal-footer .btn-view-cart").show();
				$("#js-add-to-cart-modal").find(".modal-footer .btn-continue").show();
				$("#js-add-to-cart-modal").find(".modal-footer .btn-close").hide();

			}

			$("#js-add-to-cart-modal").modal({show: true});

		}

	};

	var updateCartItemCount = function(){

		$.get(
			ajaxVars.ajaxurl,
			{
				"action": "get_cart_item_count"
			},
			function(data){
				if(data !== ""){
					$("#js-cart-count-indicator").text(data);
				}
			}
		);

	};

	var toggleNav = function(event) {

		if (event.data.status === "open") {
			$("#main-site-slide-in-nav").addClass("open");
			$(".event-breadcrumb-callout, header.navbar-default, .event-nav-wrap, .wrap, #event-contact-block, #mailing-list-cta, footer").addClass("nav-open");
		}

		if (event.data.status === "close") {
			$("#main-site-slide-in-nav").removeClass("open");
			$(".event-breadcrumb-callout, header.navbar-default, .event-nav-wrap, .wrap, #event-contact-block, #mailing-list-cta, footer").removeClass("nav-open");
		}

	};

	var landingCleanSort = function(dir) {

		$('.event-listing-wrap > div').each(function() {

			// Remove no results message(s)
			$(this).find('.no-results-message').remove();

			// Sort listings (ASC/DSC)
			var $eventBlockWrap = $(this).find('.event-block-wrap');
			$eventBlockWrap.find('.event-block').sort(function(a, b) {
				if (dir !== "DSC") {
					return a.getAttribute('data-event-time') - b.getAttribute('data-event-time');
				} else {
					return b.getAttribute('data-event-time') - a.getAttribute('data-event-time');
				}
			})
			.appendTo($eventBlockWrap);

		});

		// Show event listings
		$('.event-listing-wrap').css('opacity', '1');

	};

	var landingFilterGenerator = function() {

		// Filter generator
		var filterTypes = [];

		$('.event-block').each(function() {

			var filterType = $(this).data('event-type');

			if (filterTypes.length < 4) {

				if (filterTypes.indexOf(filterType) === -1) {
					filterTypes.push(filterType);
				}

			} else {

				return;

			}

		});

		if (filterTypes.length > 1) {

			$.each(filterTypes, function(i, v) {
				if (v === 'livestream_conference') {
					filterTypes[i] = 'livestream';
				}
			});
			
			filterTypes.sort(function(a, b) { return b.length - a.length; });

			$.each(filterTypes, function(i, v) { 
				filterTypes[i] = v + 's';
			});

			var standardIndex = filterTypes.indexOf('standards');
			
			if (standardIndex !== -1 ) {
				filterTypes.splice(standardIndex, 1);
				filterTypes.unshift('live');
			}

			$('.event-delivery-method-filter').append('<h3>Filter</h3><ul class="filter-options"></ul>');

			$.each(filterTypes, function(i, v) {

				var optionName = v.replace(/_/g, ' ');
				if (optionName === 'virtual conferences') {
					optionName = 'virtual events';
				} else if (optionName === 'livestreams') {
					optionName = 'in-person / livestream';
				} else if (optionName === 'live') {
					optionName = 'in-person';
				}

				var elmName;
				if (v === 'live') {
					elmName = 'standard';
				} else if (v === 'livestreams') {
					elmName = 'livestream_conference';
				} else {
					elmName = v.slice(0, -1);
				}

				$('.filter-options').append('<li><label class="checkbox">' + optionName + '<input type="checkbox" name="' + elmName + '" onchange="filterFunc()" /><span class="checkmark"></span></label></li>');

			});

		} else {

			$('.event-delivery-method-filter').remove();

		}

	};

	var landingFilterFunc = function() {

		// Filter functionality
		window.filterFunc = function() {

			var filterTypesSelected = [];
			var filterTypesNotSelected = [];

			$('.filter-options input').each(function() {

				if ($(this).prop('checked')) {
					filterTypesSelected.push($(this).attr('name'));
				} else {
					filterTypesNotSelected.push($(this).attr('name'));
				}

			});

			if (filterTypesSelected.length !== 0) {

				$.each(filterTypesSelected, function(i, v) {

					var self = v;

					$('.event-block').each(function() {

						if (self === $(this).data('event-type')) {
							$(this).show();
						}

					});

				});

				$.each(filterTypesNotSelected, function(i, v) {

					var self = v;

					$('.event-block').each(function() {

						if (self === $(this).data('event-type')) {
							$(this).hide();
						}

					});

				});

				$('.events-list').each(function() {

					var showEventsList = false;
					var eventslistHeading = $(this).children('h3').text();

					$(this).find('.event-block').each(function() {

						if($(this).css('display') !== 'none') {
							showEventsList = true;
						}

					});

					if (showEventsList) {

						$(this).show();

						$('.bytype-options a').each(function() {

							if($(this).text() === eventslistHeading) {
								$(this).parent().show();
							}

						});
	
					} else {
	
						$(this).hide();

						$('.bytype-options a').each(function() {

							if($(this).text() === eventslistHeading) {
								$(this).parent().hide();
							}

						});
	
					}

				});

			} else {

				$('.event-block').each(function() {

					$(this).show();

				});

				$('.events-list').each(function() {

					$(this).show();

				});

				$('.bytype-options li').each(function() {

					$(this).show();

				});

			}

		};

	};

	var backToTopBtn = function() {

		// Back to top button behaviour
		$('html').css('scroll-behavior', 'smooth');

		var upperSectionHeight = 0;
		upperSectionHeight += $('.navbar-default').outerHeight();
		upperSectionHeight += $('#breadcrumbs').outerHeight();
		upperSectionHeight += $('.main-content-container').outerHeight();

		var backToTop = $("#back-to-top-container");

		if ($(window).scrollTop() > (upperSectionHeight + 750)) {
			backToTop.show();
		}

		$(window).scroll(function() {

			if ($(window).scrollTop() > (upperSectionHeight + 750)) {

				if (backToTop.is(":hidden")) {
					backToTop.show();
				}

			} else {

				if (backToTop.is(":visible")) {
					backToTop.hide();
				}

			}

			// Function to check if elem has been scrolled into view
			function elementScrolled(elem) {
				var docViewTop = $(window).scrollTop();
				var docViewBottom = docViewTop + $(window).height();
				var elemTop = $(elem).offset().top;
				return ((elemTop <= docViewBottom) && (elemTop >= docViewTop));
			}

			if (elementScrolled('#mailing-list-cta')) {

				$('#back-to-top-container').addClass('at-footer');

			} else {

				$('#back-to-top-container').removeClass('at-footer');

			}

		});

		$("#back-to-top-btn").click(function() {
			$(window).scrollTop(upperSectionHeight);
		});

	};

	var initializeSmartForm = function() {

		$('html').css('scroll-behavior', 'smooth');

		$(document).on('nfFormReady', function(){

			// Move Canada, United States and United Kingdom to top of Country selection list
			var companySite = /\.(.+)/.exec(window.location.hostname)[1];
			
			if (companySite == 'canadianinstitute.com') {
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'americanconference.com') {
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}
			if (companySite == 'c5-online.com') {
				$($('.smart-form .listcountry-wrap option[value="GB"]')).insertAfter('.smart-form .listcountry-wrap select option:first-of-type');
				$($('.smart-form .listcountry-wrap option[value="US"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(2)');
				$($('.smart-form .listcountry-wrap option[value="CA"]')).insertAfter('.smart-form .listcountry-wrap select option:nth-of-type(3)');
			}

			// Configure tab index
			$('.smart-form input[name="fname"]').attr('tabindex', '2');
			$('.smart-form input[name="lname"]').attr('tabindex', '3');
			$('.smart-form input[name="position"]').attr('tabindex', '4');
			$('.smart-form input[name="company"]').attr('tabindex', '5');
			$('.smart-form .listcountry-wrap select').attr('tabindex', '6');
			$('.smart-form input[name="email"]').attr('tabindex', '7');
			$('.smart-form input[name="business_phone"]').attr('tabindex', '8');
			$('.smart-form input[name="mobile_phone"]').attr('tabindex', '9');

			// Email consent conditional logic
			var consentCountries = ['AU', 'CH', 'CA', 'GB', 'AT', 'BE', 'CY', 'DE', 'DK', 'ES', 'FI', 'FR', 'GR', 'IE', 'IT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SE', 'BG', 'CZ', 'EE', 'HR', 'HU', 'LT', 'LV', 'PL', 'RO', 'SI', 'BR', 'NZ'];
			var gdprCountries = ['AU', 'CH', 'GB', 'AT', 'BE', 'CY', 'DE', 'DK', 'ES', 'FI', 'FR', 'GR', 'IE', 'IT', 'LU', 'MT', 'NL', 'PT', 'SK', 'SE', 'BG', 'CZ', 'EE', 'HR', 'HU', 'LT', 'LV', 'PL', 'RO', 'SI', 'BR', 'NZ'];
			var emailConsentFieldID = $('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(5) nf-field > .nf-field-container .field-wrap').data('field-id');

			nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', false).set('required', 0);

			$('.smart-form').on('input propertychange paste', '.listcountry-container select, .email-container input', function() {

				var reqConsent = true; // This value being set to 'true' is currently overriding the consent countries check and requiring consent for all countries. To enable default behaviour (only countries in the consentCountries array require consent), change this value to 'false'.
				var forceConsent = true;
				var submitHoverTriggered = false;

				$.each(consentCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {
						reqConsent = true;
						return false;
					}

				});

				$.each(gdprCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {
						forceConsent = false;
						submitHoverTriggered = false;
						return false;
					} else {
						submitHoverTriggered = true;
					}

				});

				if ($('.smart-form .email-container input').val() != '' && reqConsent) {

					nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', true);
					$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(5) nf-field > .nf-field-container input').prop('checked', false).trigger('change');
					nfRadio.channel('fields').request('remove:error', emailConsentFieldID, 'required-error');

					if(forceConsent) {
						nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('required', 1);
					} else {
						nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('required', 0);
					}

				} else {

					nfRadio.channel('fields').request('get:field', emailConsentFieldID).set('visible', false).set('required', 0);
					$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(5) nf-field > .nf-field-container input').prop('checked', false).trigger('change');
					nfRadio.channel('fields').request('remove:error', emailConsentFieldID, 'required-error');

				}

				$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(5) nf-field > .nf-field-container input').off('change.gdprCountry');
				$('.smart-form .consent-not-required-msg').remove();

				$.each(gdprCountries, function(i, v) {

					if ($('.smart-form .listcountry-container select').val() === v) {

						$('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(5) nf-field > .nf-field-container input').on('change.gdprCountry', function() {

							if (this.checked) {
								$('.smart-form .consent-not-required-msg').remove();
							} else {
								if ($('.smart-form .consent-not-required-msg').length === 0) {
									$('<div class="consent-not-required-msg">Thank you for providing us with your email address for this request. Don’t miss out on special offers, event updates and exclusive content, check the above now to provide consent.</div>').insertAfter($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(5) nf-field > .nf-field-container .nf-after-field nf-section .nf-input-limit'));
								}
							}
							submitHoverTriggered = true;

						});

						return false;
						
					}

				});

				$('.smart-form .nf-field-container.submit-container').on('mouseenter', function() {

					if (!submitHoverTriggered && $('.smart-form .email-container input').val() != '') {
						if ($('.smart-form .consent-not-required-msg').length === 0) {
							$('<div class="consent-not-required-msg">Thank you for providing us with your email address for this request. Don’t miss out on special offers, event updates and exclusive content, check the above now to provide consent.</div>').insertAfter($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(5) nf-field > .nf-field-container .nf-after-field nf-section .nf-input-limit'));
						}
						$(window).scrollTop($('.smart-form .nf-form-wrap nf-rows-wrap > div:nth-of-type(5)').offset().top - 150);
						submitHoverTriggered = true;
					}
	
				});

			});

		});

	};

})(jQuery); // Fully reference jQuery after this point.
